$publicPath:'/tantan-door-admin';













































@import "./assets/css/element-variables.scss";
@import "./assets/font/icon-font/iconfont.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "./assets/css/global-variable";
@import "./assets/css/theme";
@import "./assets/css/index";
#app {
  width: 100%;
  height: 100%;
}
