$publicPath:'/tantan-door-admin';















































































































































































































































//@import "../../assets/css/global-variable";
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 208px;
  min-height: 400px;
  color: #ffffff;
}
.u-one-icon {
  /* margin-right: 10px; */
  font-size: 16px;
}
.el-menu-item {
  padding-right: 0;
  height: 45px;
  line-height: 45px;
  margin: 10px 0;
  transition: all ease 0s;
}
.el-menu-item i:not(.u-dot) {
  margin-left: 20px;
  height: 45px;
  line-height: 45px;
}
.el-menu-item i,
.el-submenu__title i {
  color: #fff;
}
.el-menu-item.is-active .u-title {
  padding: 10px 20px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.el-menu-item.is-active {
  padding-left: 0;
  margin-left: 20px;
  position: relative;
  background-color: #f7f8f7 !important;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  .u-title {
    padding-left: 0;
  }
  i {
    color: #206e55;
    margin-left: 0;
  }
  &::before,
  &::after {
    position: absolute;
    z-index: 99;
    content: "";
    width: 0;
    height: 0;
  }
  &::before {
    top: -8px;
    right: 0;
    border-bottom: 8px solid #f7f8f7;
    border-left: 8px solid transparent;
  }
  &::after {
    bottom: -8px;
    right: 0;
    border-right: 8px solid #f7f8f7;
    border-bottom: 8px solid transparent;
  }
}
// .el-submenu.is-active {
//   > .el-submenu__title {
//     .u-dot {
//       background: #ffffff;
//     }
//     .u-one-icon {
//       color: #ffffff;
//     }
//   }
// }

.el-submenu {
  &:nth-child(1) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon1.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(2) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon2.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(3) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon3.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(4) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon4.png") no-repeat center/20px 20px;
      }
    }
  }
  &:nth-child(5) {
    .el-submenu__title {
      .u-one-icon,
      .u-title::before {
        background: url("~assets/img/menu/icon5.png") no-repeat center/20px 20px;
      }
    }
  }
  .el-submenu__title {
    i {
      /* margin-left: 50px; */
      display: inline-block;
      width: 40px;
      height: 40px;
    }
  }
  .el-menu-item {
    padding-left: 80px !important;
  }
  .el-menu-item.is-active {
    margin-left: 60px;
    padding-left: 20px !important;
  }
}
.el-submenu.is-opened .el-submenu__title {
  i {
    background-color: #277f64;
  }
  .iconfont {
    z-index: 20;
    position: relative;
  }
}

.el-submenu.is-opened .el-submenu__title .u-title {
  background: #277f64;
  padding: 0px 0px;
  position: relative;
  width: 80px;
  display: inline-block;
  line-height: 40px;
}
.el-submenu.is-opened .el-submenu__title .u-title:before {
  content: "";
  position: absolute;
  top: 0;
  left: -40px;
  width: 40px;
  height: 100%;
  background-color: #277f64;
}
.el-submenu.is-opened .el-submenu__title .u-title:after {
  content: "";
  position: absolute;
  top: 0;
  right: -40px;
  width: 40px;
  height: 100%;
  background-color: #277f64;
}

.el-submenu.is-opened .el-menu-item .u-title {
  position: relative;
}
.el-submenu.is-opened .el-menu-item .u-title:before {
  content: "";
  position: absolute !important;
  top: 0;
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  display: inline-block;
  left: -20px;
  top: 8px;
}

.el-submenu.is-opened .el-menu-item.is-active {
  left: -40px;
  padding-left: 60px !important;
  .u-title:before {
    background-color: #277f64;
    top: 16px;
  }

  &::before {
    top: -8px;
    right: 10px;
    border-bottom: 8px solid #f7f8f7;
    border-left: 8px solid transparent;
  }
  &::after {
    bottom: -8px;
    right: 10px;
    border-right: 8px solid #f7f8f7;
    border-bottom: 8px solid transparent;
  }
}
.u-title {
  font-size: 14px;
}
.u-dot {
  display: inline-block;
  width: 8px;
  border-radius: 50%;
  height: 8px;
  background: #f7f8f7;
  margin-right: 10px;
}
.g-m-nav-box {
  padding: 0px 0;
  width: 100%;
}
.el-menu--popup .el-menu-item.is-active,
.el-menu--popup .el-menu-item {
  margin-left: 20px !important;
}
.el-menu--popup .el-submenu .el-menu-item {
  padding-left: 20px !important;
}
