$publicPath:'/tantan-door-admin';
































































































































































































































































































@import "./global-variable.scss";
.g-m-header-box {
  display: flex;
  height: 75px;
  justify-content: space-between;
  background-color: #fff;
  .header-menu {
    flex: 1;
    display: flex;
    align-items: center;
    overflow-y: hidden;

    &-ul {
      display: block;
      white-space: nowrap;
    }
    &-item {
      margin-left: 20px;
      margin-right: 40px;
      display: inline-block;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      &.cur {
        color: #0677ff;
        &:after {
          content: "";
          position: absolute;
          bottom: -30px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          height: 0;
          border-bottom: solid 2px #0677ff;
        }
      }
    }
  }
  .u-tt {
    display: flex;
    .u-h4 {
      position: relative;
      padding-left: 20px;
      font-size: $fs-main-title;
      line-height: 75px;
      .u-h4-icon {
        font-size: $fs-main-title;
        cursor: pointer;
      }
      .u-h4-text {
        font-size: 16px;
        margin-left: 10px;
        position: relative;
        top: 0px;
        color: #666666;
      }
    }
  }
  .header-right {
    float: right;
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    padding-right: 40px;

    span.right-item {
      font-size: 14px;
      color: #666666;
      margin-left: 30px;
      margin-right: 30px;
      cursor: pointer;
    }
    span.user-box {
      cursor: pointer;
      color: #666666;
      font-size: 14px;
    }
    .el-dropdown-link {
      cursor: pointer;
    }
    .el-dropdown {
      color: #666666;
    }
  }
}

.lang-icon {
  display: inline-block;
  width: 16px;
  height: 11px;
  img {
    width: 100%;
  }
}
.lang {
  border: solid 1px #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
}
.avator {
  width: 32px;
  height: 32px;
  background: #b1e7ff;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  img {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
