/*重置浏览器默认样式*/
* {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  min-height: 0;
}

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #f1f1f2;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, input, button, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

body {
  font: 12px/1 Tahoma, Helvetica, Arial, "\5b8b\4f53", sans-serif;
}

img {
  border: none;
  display: inline-block;
}

em, strong {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4, h5, h6 {
  font-size: 100%;
}

q:before, q:after {
  content: '';
}

/* 消除q前后的内容 */
button, input, select, textarea {
  font-size: 100%;
}

/* 使得表单元素在 ie 下能继承字体大小 */
input, button, textarea, select, optgroup, option {
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
}

address, cite, dfn, em, var, i {
  font-style: normal;
  color: inherit;
  line-height: inherit;
}

/* 将斜体扶正 */
/*link */
a {
  text-decoration: none;
  color: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  text-decoration: none;
}

input, button, textarea, select {
  outline: none;
}

/*清除元素轮廓*/
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 400px #fff inset;
}

html {
  overflow-x: auto;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  //min-width: 1360px;
  height: 100%;
}
