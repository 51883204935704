/* 改变主题色变量 */
@import './global-variable.scss';
$--color-primary: $fc-theme;

/* 改变 icon 字体路径变量，必需 */
$--font-path: '~element-ui/lib/theme-chalk/fonts';
.el-table{
  color:black!important;
  thead{
    color:#37404f!important;
  }
}