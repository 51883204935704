$publicPath:'/tantan-door-admin';










































































































































@import "./../../assets/css/global-variable";
.g-login-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}
