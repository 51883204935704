@font-face {
  font-family: "font_family"; /* Project id 3270391 */
  src: url('iconfont.woff2?t=1651206215322') format('woff2'),
       url('iconfont.woff?t=1651206215322') format('woff'),
       url('iconfont.ttf?t=1651206215322') format('truetype');
}

.iconfont {
  font-family: "font_family" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tanzhijian:before {
  content: "\e64e";
}

.icon-lite:before {
  content: "\e64d";
}

.icon-a-zu2527:before {
  content: "\e64c";
}

.icon-tanshang:before {
  content: "\e64b";
}

.icon-fanhui:before {
  content: "\e64a";
}

.icon-usa:before {
  content: "\e68c";
}

.icon-yonghuguanli:before {
  content: "\e644";
}

.icon-kehuguanli:before {
  content: "\e645";
}

.icon-jisuanyinziku:before {
  content: "\e646";
}

.icon-tanzhihu:before {
  content: "\e647";
}

.icon-jianpaixiangmu:before {
  content: "\e648";
}

.icon-tuijianguanli:before {
  content: "\e649";
}

.icon-caidan:before {
  content: "\e643";
}

.icon-zhongwen:before {
  content: "\e641";
}

.icon-yingwen:before {
  content: "\e642";
}

.icon-qiyong:before {
  content: "\e640";
}

.icon-a-zu291:before {
  content: "\e63e";
}

.icon-a-zu290:before {
  content: "\e63f";
}

.icon-xiangqing:before {
  content: "\e639";
}

.icon-tingyongzice:before {
  content: "\e63a";
}

.icon-tingyong:before {
  content: "\e63b";
}

.icon-bianji:before {
  content: "\e63c";
}

.icon-shanchu:before {
  content: "\e63d";
}

.icon-sousuo:before {
  content: "\e637";
}

.icon-xiaoxitixing:before {
  content: "\e638";
}

.icon-tanzhangben:before {
  content: "\e62f";
}

.icon-yinziku:before {
  content: "\e630";
}

.icon-zhangbenshenhe:before {
  content: "\e631";
}

.icon-paifangyuan:before {
  content: "\e632";
}

.icon-baobiao:before {
  content: "\e633";
}

.icon-chanpinguanli:before {
  content: "\e634";
}

.icon-shouye:before {
  content: "\e635";
}

.icon-xitongguanli:before {
  content: "\e636";
}


.is-active .icon-pcf{
  width: 16px;
  height: 16px;
  background: url("./pcf_select.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  position: relative;
  top:15px;
}
.icon-pcf{
  width: 16px;
  height: 16px;
  background: url("./pcf.png") no-repeat;
  background-size: 100%;
  display: inline-block;
  position: relative;
  top:15px;
}

